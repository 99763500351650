import qs from 'qs'

export const calculateTime = (start, end) => {
  const startTime = start.replaceAll('-', '/')
  const finishTime = end.replaceAll('-', '/')
  const currentTime = new Date(finishTime).getTime() - new Date(startTime).getTime()
  const value = Math.floor(currentTime / 1000)
  return value > 300 ? '>300' : value
}


export const getUrlCode = (url) => {
  const path = url || location.href
  const str = path.split('#')[0].split('?')[1]
  if (str) {
    const query = qs.parse(str)
    if (query.code) {
      return query.code
    }
  }
  return ''
}
