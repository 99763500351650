<template>
  <div class="loading__page flex items-center justify-center">
    <div class="load__modal">
      <div class="process__title">正在进入您的“学习”界面...</div>
      <div class="loading__line">
        <div class="process__line" :style="{ width: processNum + '%' }"></div>
      </div>
      <div class="process__text">{{ processNum }}%</div>
    </div>
    <div class="footer__text">
      <div class="row__1">推进全面依法治国，实现国家监察全面覆盖</div>
      <div class="row__2">2018年3月20日，第十三届全国人大一次会议表决通过了<br />《中华人民共和国监察法》</div>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import { mapGetters } from 'vuex'
import { getUrlCode } from '@/utils/index'
import { Notify } from 'vant'

var timer = null

export default {
  data() {
    return {
      processNum: 0,
      showPage: true
    }
  },

  computed: {
    ...mapGetters(['subscribed', 'userInfo']),

    pageProcess() {
      return {
        showPage: this.showPage,
        subscribed: this.subscribed
      }
    }
  },

  watch: {
    subscribed: {
      immediate: true,
      handler: function (v) {
        if (v === -1) {
          if (timer) clearInterval(timer)
          Dialog.alert({
            message: '请先关注公众号'
          })
        }
      }
    },

    pageProcess: {
      immediate: true,
      deep: true,
      handler: function () {
        if (!this.showPage && [1, 2].includes(this.subscribed)) {
          this.$router.push('/home/index')
        }
      }
    }
  },

  async mounted() {
    const user = await this.$store.dispatch('loadLocalUser')
    const code = getUrlCode()
    if (user.avatarUrl || user.nickName) {
      this.loadPage()
    } else if (code) {
      this.getUserWxInfo(code)
    } else {
      Dialog.confirm({
        title: '提示',
        message: '授权微信昵称和头像'
      })
        .then(() => {
          window.location.href = this.$authUrl
        })
        .catch(() => {
          this.$store.commit('SET_USER', {
            avatarUrl: this.$avatar
          })
          this.loadPage()
        })
    }
  },

  beforeDestroy() {
    if (timer) clearInterval(timer)
  },

  methods: {
    loadPage() {
      this.$store.dispatch('initWxInfo')
      this.initData()
    },

    initData() {
      timer = setInterval(() => {
        if (this.processNum >= 100) {
          clearInterval(timer)
          setTimeout(() => {
            this.showPage = false
          }, 200)
        } else {
          this.processNum += 1
        }
      }, 25)
    },

    // 通过code的获取用户昵称和头像
    async getUserWxInfo(code) {
      try {
        const res = await this.$request({
          url: '/dataqfyx/wap/user/detail',
          method: 'get',
          params: {
            code
          }
        })
        this.$store.commit('SET_USER', {
          avatarUrl: res.headimgurl,
          nickName: res.nickname
        })
        this.$store.dispatch('refreshPage')
      } catch (error) {
        Notify('获取用户信息失败')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.loading__page {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  background-image: url('@/assets/load_page_bg1.png');
  background-size: 100% calc(100% + 30px);
  background-position: 0 -30px;
  background-repeat: no-repeat;
  padding-bottom: 66px;

  .load__modal {
    width: 270px;
    height: 120px;

    .loading__text {
      width: 100%;
    }

    .process__title {
      color: #000;
      text-align: center;
      font-family: 'DingTalk JinBuTi';
      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .loading__line {
      height: 7px;
      background-color: #fff;
      border-radius: 6px;
      width: 100%;
      margin-top: 19px;
      overflow: hidden;

      .process__line {
        width: 0%;
        height: 100%;
        background: linear-gradient(90deg, #800f04 0%, #ff2c19 100%);
        border-radius: 6px;
      }
    }

    .process__text {
      text-align: center;
      margin-top: 19px;
      color: #000;
      font-family: 'DingTalk JinBuTi';
      font-size: 19px;
      font-style: normal;
      font-weight: 400;
    }
  }

  .footer__text {
    width: 100%;
    height: 110px;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0 30px;

    .row__1 {
      color: #000;
      font-family: 'PingFang SC';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
    }

    .row__2 {
      margin-top: 6px;
      color: #000;
      text-align: center;
      font-family: 'PingFang SC';
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      opacity: 0.5;
    }
  }
}
</style>
