import { app } from '@/config/index'
import request from '@/utils/axios'
// import { Toast } from 'vant'
import { categoryListMock, periodListMock, statusListMock, knowledgeListMock, swiperListMock } from './mock'

const knowledgeModule = {
  state: {
    isDebugger: false, // 本地开发模式
    categoryList: [],
    statusList: [],
    periodList: [],
    knowledgeTotal: 0,
    videoTotal: 0,
    knowledgeList: [],
    swipperList: [],
    studyInfo: {
      totalDays: 0, //总学习天数
      daysInWeek: 0, //本周学习天数
      daysInMonths: {},
      days: [], //按月份统计学习天数
      dayMax: 0,
      lastDays: 0, //连续学习天数
      //按类型统计学习文章数
      articleByType: {
        0: 0,
        1: 0
      }
    }
  },

  getters: {
    categoryList: state => state.categoryList,
    statusList: state => state.statusList,
    periodList: state => state.periodList,
    knowledgeList: state => state.knowledgeList.reverse(),
    swipperList: state => state.swipperList,
    knowledgeTotal: state => state.knowledgeTotal,
    studyInfo: state => state.studyInfo,
    videoTotal: state => state.videoTotal
  },

  mutations: {
    SET_KEY(state, data) {
      state[data.key] = data.value
    },
    REVERSE_KNOWLEDGE_LIST(state) {
      state.knowledgeList = state.knowledgeList.reverse()
    },
    UPDATE_STUDY_INFO(state, data) {
      state.studyInfo = { ...state.studyInfo, ...data }
    }
  },

  actions: {
    // 初始化学习乐园信息
    async initKnowledgeInfo(context) {
      if (context.state.isDebugger) {
        context.commit('SET_KEY', { key: 'categoryList', value: categoryListMock })
        context.commit('SET_KEY', { key: 'statusList', value: statusListMock })
        context.commit('SET_KEY', { key: 'periodList', value: periodListMock })
        context.commit('SET_KEY', { key: 'swipperList', value: swiperListMock })
        context.commit('SET_KEY', { key: 'knowledgeList', value: knowledgeListMock })
        return false
      }
      const db = app.database()
      const cateData = await db.collection('learn_category').get()
      if (cateData.data) {
        context.commit('SET_KEY', { key: 'categoryList', value: cateData.data || [] })
      }
      const statusData = await db.collection('learn_status').get()
      if (statusData.data) {
        context.commit('SET_KEY', { key: 'statusList', value: statusData.data || [] })
      }
      const periodData = await db.collection('period').get()
      if (periodData.data) {
        context.commit('SET_KEY', { key: 'periodList', value: periodData.data || [] })
      }
      const swipperData = await db.collection('learn_swipper').get()
      if (swipperData.data) {
        context.commit('SET_KEY', { key: 'swipperList', value: swipperData.data || [] })
      }
      const totalInfo = await db.collection('knowledge').get()
      if (totalInfo.data) {
        const videoList = totalInfo.data.filter(i => i.video && i.video.length)
        context.commit('SET_KEY', { key: 'videoTotal', value: videoList.length || 0 })
        context.commit('SET_KEY', { key: 'knowledgeTotal', value: totalInfo.data.length - videoList.length })
      }
    },

    // 初始化列表
    async initKnowledgeData(context, data) {
      if (context.state.isDebugger) {
        context.commit('SET_KEY', { key: 'knowledgeList', value: knowledgeListMock })
        return false
      }
      const db = app.database()
      const listData = await db.collection('knowledge').where(data).get()
      if (listData.data) {
        context.commit('SET_KEY', { key: 'knowledgeList', value: listData.data || [] })
      }
    },

    // 加载学习信息
    async getUserStudyData(context) {
      try {
        const res = await request(`/dataqfyx/wap/study/statistic`)
        const days = []
        let dayMax = 0
        for (const key in res.daysInMonths) {
          days.push({ key, value: res.daysInMonths[key] })
          if (res.daysInMonths[key] > dayMax) dayMax = res.daysInMonths[key]
        }
        context.commit('UPDATE_STUDY_INFO', { ...res, days, dayMax })
      } catch (error) {
        console.log(error)
      }
    },

    // 分享得积分上报
    async shareAddCode() {
      try {
        await request({
          url: `/dataqfyx/wap/study/share`,
          method: 'post'
        })
        // Toast('获得分享积分+')
      } catch (error) {
        console.log(error)
      }
    }
  }
}

export default knowledgeModule
