<template>
  <div class="footer__bar">
    <div v-for="item in tabList" :key="item.key" class="footer__item" @click="pageChange(item.path)">
      <img class="item__icon" :src="activeKey === item.key ? item.activeIcon : item.icon" alt="" />
      <span :class="['item__name', activeKey === item.key && 'active']">{{ item.name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      path: '/home/index',
      tabList: [
        {
          key: 1,
          name: '学习',
          icon: require('@/assets/footer_index_icon.png'),
          activeIcon: require('@/assets/footer_index_icon_active.png'),
          path: '/home/index'
        },
        {
          key: 2,
          name: '测试',
          icon: require('@/assets/footer_test_icon.png'),
          activeIcon: require('@/assets/footer_test_icon_active.png'),
          path: '/home/testEntry'
        },
        {
          key: 3,
          name: '我的',
          icon: require('@/assets/footer_mine_icon.png'),
          activeIcon: require('@/assets/footer_mine_icon_active.png'),
          path: '/home/mine'
        }
      ]
    }
  },

  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler: function (v) {
        this.path = v.path
      }
    }
  },

  computed: {
    activeKey() {
      const item = this.tabList.find(i => i.path === this.path)
      if (item) return item.key
      return this.tabList[0].key
    }
  },

  methods: {
    pageChange(path) {
      path && this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.footer__bar {
  height: 56px;
  flex-shrink: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .footer__item {
    flex: 1;
    flex-shrink: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .item__icon {
      width: 22px;
      height: 22px;
      margin-bottom: 4px;
    }

    .item__name {
      color: #333;
      font-family: 'PingFang SC';
      font-size: 12px;
      font-weight: 500;

      &.active {
        color: #d91e0c;
      }
    }
  }
}
</style>
