import Vue from 'vue'
import Vuex from 'vuex'
import { app, NEXT_PUBLIC_APPID } from '@/config/index'
import request from '@/utils/axios'
import knowledgeModule from './modules/knowledge'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isDebugger: false, // 本地开发模式
    showLoading: false,
    userInfo: {
      id: '',
      name: '',
      type: '',
      phone: '',
      departId: '',
      departName: '',
      openid: '',
      avatarUrl: '',
      nickName: ''
    },
    subscribed: 0, // 是否已经订阅公众号 0查询中或查询失败 -1未订阅 1已订阅 2用户未注册
    openId: '',
    competitionTitles: [], // 每一期的标题内容
    competitions: [], // 每一期参与数据
    currentCompetition: {} // 当前参与期数
  },

  getters: {
    openId: state => state.openId || state.userInfo.openid,
    userInfo: state => state.userInfo,
    subscribed: state => state.subscribed,
    competitionTitles: state => state.competitionTitles,
    competitions: state => state.competitions,
    isDebugger: state => state.isDebugger
  },

  mutations: {
    SET_OPEN_ID(state, value) {
      state.openId = value
      localStorage.setItem('openId', value)
    },
    SET_USER(state, value) {
      state.userInfo = { ...state.userInfo, ...value }
      localStorage.setItem('userInfo', JSON.stringify(state.userInfo))
    },
    SET_KEY(state, data) {
      state[data.key] = data.value
    },
    CLEAR_STORAGE() {
      localStorage.clear('userInfo')
      localStorage.clear('openId')
    }
  },

  actions: {
    // 初始化用户信息
    async initWxInfo(context) {
      if (context.state.isDebugger) {
        context.commit('SET_OPEN_ID', 'oeBOXw-lDOViwfhIEEyjZaORRg8k')
        await context.dispatch('refreshUserInfo')
      } else {
        try {
          const auth = app.auth({ persistence: 'local' })
          let loginState = await auth.getLoginState()
          const provider = auth.weixinAuthProvider({
            appid: NEXT_PUBLIC_APPID,
            scope: 'snsapi_base'
          })
          if (!loginState) {
            loginState = await provider.getRedirectResult()
          }
          if (loginState) {
            console.log('登录信息', loginState)
            const openId = loginState.user.openid
            context.commit('SET_OPEN_ID', openId)
            setTimeout(async () => {
              await context.dispatch('refreshUserInfo')
            }, 20)
          } else {
            await provider.signInWithRedirect()
          }
        } catch (error) {
          console.log(error)
        }
      }
    },

    // 初始化本地用户信息
    loadLocalUser(context) {
      return new Promise(resolve => {
        let userInfo = context.state.userInfo
        const userStorage = localStorage.getItem('userInfo')
        if (userStorage) {
          try {
            userInfo = JSON.parse(userStorage)
          } catch (error) {
            userInfo = context.state.userInfo
          }
        }
        context.commit('SET_USER', userInfo)
        resolve({ ...userInfo })
      })
    },

    // 刷新用户信息
    async refreshUserInfo(context) {
      try {
        let userInfo = await context.dispatch('loadLocalUser')
        const res = await request({
          url: '/dataqfyx/wap/user/check',
          method: 'post',
          data: {
            phone: userInfo.phone,
            type: userInfo.type
          }
        })
        if (res === null) {
          // 用户未注册
          context.commit('SET_KEY', { key: 'subscribed', value: 2 })
        } else if (typeof res === 'object') {
          context.commit('SET_USER', res)
          context.commit('SET_KEY', { key: 'subscribed', value: 1 })
        } else if (res === -1) {
          context.commit('SET_KEY', { key: 'subscribed', value: -1 })
        } else if (res === -2) {
          context.commit('CLEAR_STORAGE')
          context.dispatch('refreshPage')
        }
      } catch (error) {
        console.log(error)
      }
    },

    // 初始化答题期数信息
    async initCompetitionBase(context) {
      try {
        const db = app.database()
        const { data } = await db.collection('every_compotition_titles').get()
        context.commit('SET_KEY', { key: 'competitionTitles', value: data })
        const res = await request({
          url: '/dataqfyx/wap/competition/list',
          method: 'get'
        })
        const list = res.map(i => {
          return { ...i, isOutOfDate: i.endTime ? new Date(i.endTime.replaceAll('-', '/')).getTime() < Date.now() : true }
        })
        context.commit('SET_KEY', { key: 'competitions', value: list })
      } catch (error) {
        throw new Error()
      }
    },

    refreshPage() {
      setTimeout(() => {
        window.location.href = location.origin + `/#/?time=${Date.now()}`
      }, 20)
    }
  },

  modules: {
    knowledgeModule
  }
})
