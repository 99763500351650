<template>
  <div class="swipper__box">
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#843E34">
      <van-swipe-item v-for="item in swipperList" :key="item._id">
        <!-- <img class="swiper__img" :src="item.image" alt="" @click="gotoPage(item)" /> -->
        <van-image width="100%" height="210" lazy-load :src="item.image" @click="gotoPage(item)" />
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {}
  },

  computed: {
    ...mapGetters(['swipperList'])
  },

  methods: {
    gotoPage(item) {
      if (item.url) {
        // window.location.href = item.url
        window.open(item.url, '_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.swipper__box {
  width: 100%;
  height: 210px;
  flex-shrink: 0;

  .swiper__img {
    width: 100%;
    height: 210px;
    display: block;
  }

  ::v-deep {
    .van-swipe__indicator {
      height: 4px;
      background-color: #fff;
      width: 15px;
      border-radius: 4px;
      opacity: 1;
      transition: all 0.6s;

      &.van-swipe__indicator--active {
        background-color: #843e34;
        width: 24px;
        border-radius: 6px;
      }
    }
  }
}
</style>
