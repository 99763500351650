import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'tailwindcss/tailwind.css'
import '@/style/reset.scss'
import Vant from 'vant'
import 'vant/lib/index.css'
import request from '@/utils/axios'
import VConsole from 'vconsole'
import { DEFAULT_AVATAR, AUTH_URL } from '@/config/index'
import { Lazyload } from 'vant'

Vue.use(Vant)
Vue.use(Lazyload)

Vue.config.productionTip = false
Vue.prototype.$request = request
Vue.prototype.$avatar = DEFAULT_AVATAR
Vue.prototype.$authUrl = AUTH_URL

if (location.href && location.href.includes('showConsole')) {
  // eslint-disable-next-line no-unused-vars
  const vConsole = new VConsole()
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
