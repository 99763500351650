<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['subscribed'])
  },

  data() {
    return {}
  },

  watch: {
    subscribed: {
      immediate: true,
      handler: function (v) {
        if (v === 0 && this.$route.path !== '/loadingPage') {
          this.$router.replace('/loadingPage')
        }
      }
    },

    $route: {
      immediate: true,
      deep: true,
      handler: function (v) {
        this.path = v.path
        document.title = v.meta.name || ''
      }
    }
  },

  methods: {}
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}
</style>
