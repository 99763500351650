<template>
  <div class="active__title--module">
    <img class="title__icon" src="@/assets/index_tab_left.png" alt="" />
    <span class="title__text" :style="{ fontSize: fontSize + 'px', color: titleColor, minWidth: minWidth }">{{ title }}</span>
    <img class="title__icon" src="@/assets/index_tab_right.png" alt="" />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: () => ''
    },
    fontSize: {
      type: Number,
      default: () => 15
    },
    titleColor: {
      type: String,
      default: () => 'rgba(51, 51, 51, 0.9)'
    },
    minWidth: {
      type: Number,
      default: () => 54
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.active__title--module {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(51, 51, 51, 0.9);
  text-align: center;
  font-family: 'PingFang SC';
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  flex-shrink: 0;

  .title__text {
    display: inline-block;
  }

  .title__icon {
    width: 15px;
    height: 20px;

    &:nth-of-type(1) {
      margin-right: 5px;
    }
    &:nth-of-type(2) {
      margin-left: 5px;
    }
  }
}
</style>
