import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/index.vue'
import Layout from '../layout/index.vue'
import LoadingPage from '@/views/loadingPage/index.vue'

Vue.use(VueRouter)

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'DefaultPage',
    component: LoadingPage,
    meta: {
      name: ''
    }
  },
  {
    path: '/loadingPage',
    name: 'LoadingPage',
    component: LoadingPage,
    meta: {
      name: ''
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/register/index.vue'),
    meta: {
      name: ''
    }
  },
  {
    path: '/departSearch',
    name: 'DepartSearch',
    component: () => import('../views/departSearch/index.vue'),
    meta: {
      name: '选择单位'
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Layout,
    redirect: '/home/index',
    children: [
      {
        path: '/home/index',
        name: 'Index',
        component: HomeView,
        meta: {
          name: '学习园地'
        }
      },
      {
        path: '/home/testEntry',
        name: 'TestEntry',
        component: () => import('../views/testEntry/index.vue'),
        meta: {
          name: '',
          title: '答题入口页面'
        }
      },
      {
        path: '/home/mine',
        name: 'Mine',
        component: () => import('../views/mine/index.vue'),
        meta: {
          name: '我的'
        }
      }
    ]
  },
  {
    path: '/knowledgeDetail',
    name: 'KnowledgeDetail',
    component: () => import('../views/knowledgeDetail/index.vue'),
    meta: {
      name: '',
      title: '学习园地详情'
    }
  },
  {
    path: '/answer',
    name: 'Answer',
    component: () => import('../views/answer/index.vue'),
    meta: {
      name: '',
      title: '答题'
    }
  },
  {
    path: '/answeringEnd',
    name: 'AnsweringEnd',
    component: () => import('../views/answeringEnd/index.vue'),
    meta: {
      name: '',
      title: '答题结束'
    }
  },
  {
    path: '/mine/detail',
    name: 'MineDetail',
    component: () => import('../views/mineDetail/index.vue'),
    meta: {
      name: '',
      title: '个人信息'
    }
  },
  {
    path: '/mine/departmentRanking',
    name: 'DepartmentRanking',
    component: () => import('../views/departmentRanking/index.vue'),
    meta: {
      name: '',
      title: '我的排名'
    }
  },
  {
    path: '/mine/myResults',
    name: 'MyResults',
    component: () => import('../views/myResults/index.vue'),
    meta: {
      name: '',
      title: '我的成绩'
    }
  },
  {
    path: '/mine/myStudyRecord',
    name: 'myStudyRecord',
    component: () => import('../views/myStudyRecord/index.vue'),
    meta: {
      name: '',
      title: '我的学习记录'
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
