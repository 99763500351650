import axios from 'axios'
import { NEXT_PUBLIC_BACKEND_URL } from '@/config/index'

// 创建axios实例
const request = axios.create({
  baseURL: NEXT_PUBLIC_BACKEND_URL,
  timeout: 30000
})

// 返回其他状态吗
request.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500 // 默认的
}

/**
 * HTTP request 拦截
 */
request.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    config.headers['openid'] = window.localStorage.getItem('openId') || ''
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

/**
 * HTTP response 拦截
 */
request.interceptors.response.use(res => {
  const status = Number(res.status) || 200
  if (res.data.code === 401) {
    window.location.href = '/'
  } else if (status === 200 && (res.data.code === 200 || res.data.code === undefined)) {
    return Promise.resolve(typeof res.data.data === 'undefined' ? res.data : res.data.data)
  } else {
    return Promise.reject(res.data)
  }
})

export default request
