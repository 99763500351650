<template>
  <div class="lay__out">
    <div class="page__con">
      <router-view />
    </div>
    <FooterBar />
  </div>
</template>

<script>
import FooterBar from './components/footer/index.vue'

export default {
  components: { FooterBar },
  data() {
    return {}
  },
  methods: {},
  mounted() {}
}
</script>

<style lang="scss" scoped>
.lay__out {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS 11.2+ */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS 11.2+ 和安卓 */

  .page__con {
    flex: 1;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}
</style>
