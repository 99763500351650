<template>
  <div class="home__page">
    <SwipperImg />
    <!-- <div class="sort__row">
      <ActiveTitle title="榜单" :fontSize="16" titleColor="#843E34" :minWidth="42"></ActiveTitle>
      <div class="right__ls">
        <div class="right__item">
          <img class="item__icon" src="@/assets/gload__icon.png" alt="" />
          <div class="item__name">{{ '--' }}</div>
        </div>
        <div class="right__item">
          <img class="item__icon" src="@/assets/gload__icon1.png" alt="" />
          <div class="item__name">{{ '--' }}</div>
        </div>
        <div class="right__item">
          <img class="item__icon" src="@/assets/gload__icon2.png" alt="" />
          <div class="item__name">{{ '--' }}</div>
        </div>
      </div>
    </div> -->
    <!-- <div class="modal__type">
      <div class="type__item" @click="showModal">
        <span>期数</span>
        <img class="type__icon1" src="@/assets/index_type_icon1.png" alt="" />
      </div>
      <div class="type__item" @click="showModal">
        <span>分类</span>
        <img class="type__icon1" src="@/assets/index_type_icon1.png" alt="" />
      </div>
      <div class="type__item" @click="showModal">
        <span>状态</span>
        <img class="type__icon1" src="@/assets/index_type_icon1.png" alt="" />
      </div>
      <div class="type__item" @click="changeSort">
        <span>排序</span>
        <img class="type__icon2" src="@/assets/index_type_icon2.png" alt="" />
      </div>
    </div> -->
    <div class="modal__type scroll__box hs">
      <div
        @click="changeSelect('category', item._id)"
        :class="['tab__item', query.category === item._id && 'active']"
        v-for="item in categoryList"
        :key="item._id"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="info__modal hs">
      <div v-if="knowledgeList.length">
        <div class="info__item" v-for="item in knowledgeList" :key="item._id" @click="gotoDetail(item)">
          <div class="left__img">
            <van-image width="103" height="103" lazy-load :src="item.bg_img" />
          </div>
          <div class="right__info">
            <div class="title__txt">{{ item.title }}</div>
            <div class="info__label">
              <span class="label__item">{{ getCateName(item) }}</span>
            </div>
            <!-- <div class="info__views">
              <img class="info__eyes" src="@/assets/index_info_views.png" alt="" />
              <span class="info__num">{{ item.read_count }}</span>
            </div> -->
          </div>
        </div>
      </div>
      <div class="empty__box" v-else>
        <img class="empty__icon" src="@/assets/index_empty_icon.png" alt="" />
        <div>暂无数据</div>
      </div>
    </div>

    <van-popup v-model="modal" position="bottom" round closeable :style="{ height: '70%' }">
      <div class="dialog__module">
        <div class="dialog__title">
          <ActiveTitle title="学习园地" :fontSize="20"></ActiveTitle>
        </div>
        <div class="dialog__con">
          <div class="select__row">
            <div class="left__label">期数</div>
            <div class="right__label">
              <span
                @click="changeSelect('period', item._id)"
                :class="['dialog__label', query.period === item._id && 'active']"
                v-for="(item, index) in periodList"
                :key="index"
                >{{ item.name }}</span
              >
            </div>
          </div>
          <div class="select__row">
            <div class="left__label">状态</div>
            <div class="right__label">
              <span
                @click="changeSelect('status', item._id)"
                :class="['dialog__label', query.status === item._id && 'active']"
                v-for="(item, index) in statusList"
                :key="index"
                >{{ item.name }}</span
              >
            </div>
          </div>
          <div class="select__row">
            <div class="left__label">主题</div>
            <div class="right__label">
              <span
                @click="changeSelect('category', item._id)"
                :class="['dialog__label', query.category === item._id && 'active']"
                v-for="(item, index) in categoryList"
                :key="index"
                >{{ item.name }}</span
              >
            </div>
          </div>
        </div>
        <div class="confirm__btn" @click="loadData">确定</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Toast, Notify } from 'vant'
import SwipperImg from '@/views/home/swipperImg/index.vue'
import ActiveTitle from '@/components/activeTitle/index.vue'

export default {
  components: { SwipperImg, ActiveTitle },

  data() {
    return {
      tab: 1,
      modal: false,
      query: {
        period: '',
        category: '',
        status: ''
      },
      sortList: []
    }
  },

  computed: {
    ...mapGetters(['categoryList', 'statusList', 'periodList', 'knowledgeList'])
  },

  watch: {
    categoryList: {
      immediate: true,
      deep: true,
      handler: function (v) {
        if (v.length) {
          this.query.category = v[0]['_id']
          setTimeout(() => {
            this.loadData()
          }, 20)
        }
      }
    }
  },

  mounted() {
    this.$store.dispatch('initKnowledgeInfo')
    this.loadData()
    this.initRank()
  },

  methods: {
    changeTab(type) {
      if (type === 2) return Toast('暂未开放~')
      this.tab = type
    },

    getCateName(item) {
      const data = this.categoryList.find(i => i._id === item.category)
      return data ? data.name : '未知'
    },

    showModal() {
      this.modal = true
    },

    changeSort() {
      this.$store.commit('REVERSE_KNOWLEDGE_LIST')
    },

    changeSelect(type, value) {
      // this.query[type] = this.query[type] === value ? '' : value
      this.query[type] = value
      this.loadData()
    },

    loadData() {
      const data = {}
      for (const key in this.query) {
        if (this.query[key]) {
          data[key] = this.query[key]
        }
      }
      this.$store.dispatch('initKnowledgeData', data)
      this.modal = false
    },

    gotoDetail(item) {
      this.$router.push('/knowledgeDetail?id=' + item._id)
    },

    async initRank() {
      try {
        const res = await this.$request({
          url: `/dataqfyx/wap/study/deptRank`,
          method: 'get'
        })
        this.sortList = res.map(i => i.deptName)
        console.log(res)
      } catch (error) {
        Notify('服务异常,请稍后重试')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home__page {
  width: 100%;
  height: 100%;
  background-color: #f3ebde;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;

  .hidden__img {
    display: inline-block;
    width: 1px;
    height: 1px;
    overflow: hidden;
    opacity: 0;
  }

  .sort__row {
    flex-shrink: 0;
    width: 100%;
    background: linear-gradient(91deg, #ffebc1 0.51%, #fff 97.88%);
    height: 46px;
    display: flex;
    align-items: center;
    padding: 0 10px;

    .right__ls {
      flex: 1;
      display: flex;
      align-items: center;
      width: 100%;
      overflow: hidden;
      margin-left: 18px;

      .right__item {
        width: 33.33%;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        padding-left: 6px;

        .item__icon {
          width: 23px;
          height: 28px;
          flex-shrink: 0;
        }

        .item__name {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: #333;
          font-family: 'PingFang SC';
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding-left: 3px;
        }
      }
    }
  }
  .divider {
    height: 14px;
    flex-shrink: 0;
    background-color: #fff;
  }

  .modal__type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;

    .type__item {
      flex: 1;
      display: flex;
      height: 29px;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      background: #ebd8cd;
      color: #6d3619;
      font-family: 'PingFang SC';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      margin-right: 11px;

      &:nth-last-of-type(1) {
        margin-right: 0;
      }

      .type__icon1 {
        width: 8px;
        height: 6px;
        margin-left: 4px;
      }

      .type__icon2 {
        width: 10px;
        height: 10px;
        margin-left: 4px;
      }
    }

    &.scroll__box {
      flex-flow: nowrap;
      justify-content: flex-start;
      overflow-x: auto;
      padding: 0 4px;
      padding-top: 5px;

      .tab__item {
        padding: 0px 12px;
        display: flex;
        align-items: center;
        height: 34px;
        position: relative;
        color: #999;
        font-size: 13px;
        line-height: 1px;
        box-sizing: border-box;
        font-weight: 600;
        transition: all 0.4s;
        flex-shrink: 0;

        &::after {
          content: '';
          position: absolute;
          width: calc(100% - 36px);
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 2px;
          border-radius: 8px;
          background-color: transparent;
          transition: all 0.4s;
        }

        &.active {
          color: #333;

          &::after {
            content: '';
            background-color: #d91e0b;
          }
        }
      }
    }
  }

  .info__modal {
    background: #fff;
    width: 100%;
    flex: 1;
    height: 100%;
    overflow-y: auto;

    .info__item {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      padding: 14px 16px;
      border-bottom: 1px solid #ededed;

      .left__img {
        width: 103px;
        height: 103px;
        flex-shrink: 0;
        border-radius: 6px;
        overflow: hidden;
      }

      .right__info {
        flex: 1;
        width: 100%;
        padding-left: 12px;
        height: 100%;
        overflow: hidden;

        .title__txt {
          color: #333;
          font-family: 'PingFang SC';
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: 1px;
        }

        .info__label {
          margin-top: 8px;

          .label__item {
            height: 23px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 0 8px;
            color: #843e34;
            font-family: 'PingFang SC';
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            border-radius: 6px;
            background: #ebd8cd;
          }
        }

        .info__views {
          margin-top: 28px;
          height: 17px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: #999;
          font-family: 'PingFang SC';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;

          .info__eyes {
            width: 16px;
            height: 16px;
            margin-right: 4px;
          }
        }
      }
    }

    .empty__box {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #804e33;
      font-family: 'PingFang SC';
      font-size: 13px;
      font-weight: 500;
      padding-bottom: 30px;

      .empty__icon {
        width: 160px;
        height: 136px;
        margin-bottom: 14px;
      }
    }
  }
}

.dialog__module {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .dialog__title {
    height: 77px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-bottom: 6px;
  }

  .confirm__btn {
    color: #fff;
    font-family: 'PingFang SC';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 60px);
    height: 50px;
    flex-shrink: 0;
    border-radius: 29px;
    background: linear-gradient(95deg, #e06b00 13.06%, #d91e0b 90.75%);
    margin: 28px 0;
  }

  .dialog__con {
    flex: 1;
    width: 100%;
    overflow-y: auto;

    .select__row {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 16px;

      &:nth-of-type(1) {
        margin-top: 0;
      }

      &:nth-last-of-type(1) {
        .right__label {
          border-bottom: none;
        }
      }

      .left__label {
        width: 57px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #666;
        font-family: 'PingFang SC';
        font-size: 15px;
        font-weight: 400;
        padding-left: 6px;
      }

      .right__label {
        flex: 1;
        overflow: hidden;
        width: 100%;
        margin: 0 15px;
        white-space: wrap;
        border-bottom: 1px solid #eee;

        .dialog__label {
          display: inline-flex;
          align-items: center;
          height: 35px;
          padding: 0 16px;
          border-radius: 8px;
          background: #f3f4f6;
          color: #333;
          font-family: 'PingFang SC';
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-right: 12px;
          margin-bottom: 16px;

          &.active {
            background: #fee9e2;
            color: #6e3719;
          }
        }
      }
    }
  }
}
</style>
